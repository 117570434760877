  /* DM sans */
  @font-face {
    font-family: DMSansRegular;
    src: url('./fonts/DMSans-Regular.ttf');
  }
@font-face {
  font-family: DMSansMedium;
  src: url('./fonts/DMSans-Medium.ttf');
}
@font-face {
  font-family: DMSansBold;
  src: url('./fonts/DMSans-Bold.ttf');
}

  /* Kaisei */
@font-face {
  font-family: KaiseiDecolReg;
  src: url('./fonts/KaiseiDecol-Regular.ttf');
}
@font-face {
  font-family: KaiseiDecolMed;
  src: url('./fonts/KaiseiDecol-Medium.ttf');
}
@font-face {
  font-family: KaiseiDecolBold;
  src: url('./fonts/KaiseiDecol-Bold.ttf');
}

  /* Mundial */
@font-face {
  font-family: MundialBlack;
  src: url('./fonts/MundialBlack.otf');
}
@font-face {
  font-family: MundialBlackItalic;
  src: url('./fonts/MundialBlackItalic.otf');
}
@font-face {
  font-family: MundialBold;
  src: url('./fonts/MundialBold.otf');
}
@font-face {
  font-family: MundialBoldItalic;
  src: url('./fonts/MundialBoldItalic.otf');
}
@font-face {
  font-family: MundialDemiBold;
  src: url('./fonts/MundialDemibold.otf');
}
@font-face {
  font-family: MundialDemiBoldItalic;
  src: url('./fonts/MundialDemiboldItalic.otf');
}
@font-face {
  font-family: MundialHair;
  src: url('./fonts/MundialHair.otf');
}
@font-face {
  font-family: MundialHairItalic;
  src: url('./fonts/MundialHairItalic.otf');
}
@font-face {
  font-family: MundialItalic;
  src: url('./fonts/MundialItalic.otf');
}
@font-face {
  font-family: MundialLight;
  src: url('./fonts/MundialLight.otf');
}
@font-face {
  font-family: MundialLightItalic;
  src: url('./fonts/MundialLightItalic.otf');
}
@font-face {
  font-family: MundialRegular;
  src: url('./fonts/MundialRegular.otf');
}
@font-face {
  font-family: MundialThin;
  src: url('./fonts/MundialThin.otf');
}
@font-face {
  font-family: MundialThinItalic;
  src: url('./fonts/MundialThinItalic.otf');
}


html {
  padding: 0;
  margin: 0
}

.MuiButton-root {
  text-transform: unset !important;
}
a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #b09e9e;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance:textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

